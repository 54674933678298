import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, select } from 'redux-saga/effects';
// features
import { makeSelectAllDriversList } from '../../../available-driver/selectors';
import { getAllDriversListSuccess, resetAllDriversListAndPagination } from '../../../available-driver/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
// feature sockets
import * as A from '../../actions';
import * as LC from '../../constants';
import { clientTopics, openStompClient, getConnectedPromise, unsubscribeFromDrivers } from '../../helpers';
//////////////////////////////////////////////////

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    const { type, payload } = event;

    const socketDataType = R.path(['data', LC.SOCKET_DATA_TYPE], payload);

    if (R.equals(type, LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED)) {
      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_RESERVATION)) {
        yield put(A.socketAvailableDriversReservationReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_STATUS)) {
        yield put(A.socketAvailableDriversStatusReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_NOTE)) {
        yield put(A.socketAvailableDriversNoteReceived(payload));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_AVAILABLE_DRIVERS_LAST_KNOWN_LOCATION)) {
        yield put(A.socketAvailableDriversLocationReceived(payload));
      }
    }
  }
}

const runStompClientAllDrivers = (
  client: Object,
  { accessToken, driverGuids }: Object,
) => eventChannel((emit: Function) => {
  driverGuids.forEach((driverGuid: Object) => {
    const topic = clientTopics.getAvailableDrivers(driverGuid);

    client.subscribe(topic, (message: Object) => {
      emit({
        type: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED,
        payload: {
          driverGuid,
          data: JSON.parse(message.body),
          type: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED,
        },
      });
    }, { id: driverGuid, 'access-token': accessToken });
  });

  return () => unsubscribeFromDrivers(driverGuids, accessToken, client);
});

function* runStompClientAllDriversOnChangeListSaga(client: Object, { payload }: Object, data: Object) {
  const driverListGuids = R.map(R.prop(GC.FIELD_GUID), payload);

  const eventChannel = yield call(
    runStompClientAllDrivers,
    client,
    R.assoc(GC.FIELD_DRIVER_GUIDS, driverListGuids, data),
  );

  yield call(watchEventChannelMessagesSaga, eventChannel);
}

function* watchGetAllDriversListSuccessSaga(client: Object, data: Object) {
  while (true) { // eslint-disable-line
    const action = yield take(getAllDriversListSuccess);

    yield fork(runStompClientAllDriversOnChangeListSaga, client, action, data);
  }
}

function* watchResetAllDriversListAndPaginationSaga(client: Object, { accessToken }: Object) {
  while (true) { // eslint-disable-line
    const { payload } = yield take(resetAllDriversListAndPagination);

    unsubscribeFromDrivers(payload, accessToken, client);
  }
}

function* checkAllDriversSocketErrorOrClose(client: Object) {
  const onDisconnect = new Promise((res: Function) => {
    client.onWebSocketError = (payload: Object) => res(payload); // eslint-disable-line
    client.onWebSocketClose = (payload: Object) => res(payload); // eslint-disable-line
  });

  yield onDisconnect;

  yield put(A.socketAllDriversDisconnectRequest());

  yield delay(5000);

  const itemList = yield select(makeSelectAllDriversList());

  if (G.isNotNilAndNotEmpty(itemList)) {
    yield put(A.socketAllDriversReconnectRequest(R.keys(itemList)));
  }
}

function* waitForSocketAllDriversConnected(client: Object) {
  yield getConnectedPromise(client);

  yield put(A.socketAllDriversConnected());
}

function* watchSocketAllDriversRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketAllDriversConnectRequest),
        reconnectAction: take(A.socketAllDriversReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.fleetSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: G.getAmousCurrentUserGuidFromWindow(),
        driverGuids: G.getPropFromObject('payload', action),
        socketChannelType: LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(checkAllDriversSocketErrorOrClose, client);

      yield fork(waitForSocketAllDriversConnected, client);

      yield take(A.socketAllDriversConnected);

      const eventChannel = yield call(runStompClientAllDrivers, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketAllDriversDisconnectRequest),
        task: all([
          fork(watchGetAllDriversListSuccessSaga, client, data),
          fork(watchResetAllDriversListAndPaginationSaga, client, data),
          call(watchEventChannelMessagesSaga, eventChannel),
        ]),
      });

      if (cancel) eventChannel.close();
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketAllDriversRequestSaga');
  }
}

function* allDriversSocketWatcherSaga() {
  yield fork(watchSocketAllDriversRequestSaga);
}

export default allDriversSocketWatcherSaga;
