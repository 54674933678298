// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_FIRST_NAME}`,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: `${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_LAST_NAME}`,
    name: `${G.getWindowLocale('titles:driver', 'Driver')}: ${G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: `${GC.FIELD_TRUCK}.${GC.FIELD_UNIT_ID}`,
    name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${G.getWindowLocale('titles:unit-id', 'Unit Id')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_TOLL_CHARGES_SOURCE,
    name: G.getWindowLocale('titles:source', 'Source'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOLL_CHARGES_AMOUNT,
    name: G.getWindowLocale('titles:amount', 'Amount'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS_2,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOLL_CHARGES_AGENCY,
    name: G.getWindowLocale('titles:agency', 'Agency'),
  },
  {
    type: 'date',
    value: GC.FIELD_TOLL_CHARGES_POSITING_DATE,
    name: G.getWindowLocale('titles:posting-date', 'Posting Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOLL_CHARGES_LICENCE_PLATE,
    name: G.getWindowLocale('titles:license-plate', 'License Plate'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOLL_CHARGES_LOCATION_NAME,
    name: G.getWindowLocale('titles:location-name', 'Location Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOLL_CHARGES_TRANSPONDER_ID,
    name: G.getWindowLocale('titles:transponder-id', 'Transponder ID'),
  },
  {
    type: 'date',
    value: GC.FIELD_TOLL_CHARGES_TRANSACTION_DATE,
    name: G.getWindowLocale('titles:transaction-date', 'Transaction Date'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_TOLL_CHARGES_TRANSACTION_TYPE,
    placeholder: { text: 'Choose', key: 'titles:choose' },
    name: G.getWindowLocale('titles:transaction-type', 'Transaction Type'),
    options: [
      {
        value: ENUMS.ENUM_TOLL,
        label: G.getWindowLocale('titles:toll', 'Toll'),
      },
      {
        value: ENUMS.ENUM_VIDEO_TOLL,
        label: G.getWindowLocale('titles:video-toll', 'Video Toll'),
      },
    ],
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:updated-by', 'Updated By'),
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: G.getWindowLocale('titles:updated-date', 'Updated Date'),
  },
];
