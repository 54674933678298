/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, select } from 'redux-saga/effects';
// common
import { initialDataLoadSuccess } from '../../../common/actions';
// features
import PC from '../../permission/role-permission';
import { makeSelectCurrentUserData } from '../../auth/selectors';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectAuthorizedLoadBoards } from '../../load-board/selectors';
import { refreshTokenRequest, refreshTokenSuccess } from '../../auth/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature sockets
import * as A from '../actions';
import * as LC from '../constants';
import {
  loadBoardTopics,
  openStompClient,
  getConnectedPromise,
  areSocketReconnectsOver,
  resetSocketReconnectCounter,
} from '../helpers';
//////////////////////////////////////////////////
function* watchSendStompMessageRequestSaga(client: Object) {
  while (true) { // eslint-disable-line
    const action = yield take(A.socketSendMessageRequest);

    const {
      payload: { destination, body = '', headers = {} },
    } = action;

    client.send(destination, body, headers);
  }
}

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    const { data, type } = event;

    switch (type) {
      case LC.SOCKET_CHANNEL_LOAD_BOARD_RESULT_RECEIVED:
        yield put(A.socketLBResultReceived(data));
        break;
      case LC.SOCKET_CHANNEL_LOAD_BOARD_FILTER_STATE_RECEIVED:
        yield put(A.socketFilterStateReceived(data));
        break;
      case LC.SOCKET_CHANNEL_LOAD_BOARD_STATE_RECEIVED:
        yield put(A.socketLBStateReceived(data));
        break;
      case LC.SOCKET_CHANNEL_LOAD_BOARD_CONFIG_STATE_RECEIVED:
        yield put(A.socketLBConfigStateReceived(data));
        break;
      case LC.SOCKET_CHANNEL_LOAD_BOARD_POSTED_SHIPMENT_STATE_RECEIVED:
        yield put(A.socketPostedShipmentStateReceived(data));
        break;
      case LC.SOCKET_CHANNEL_LOAD_BOARD_BOOKED_SHIPMENT_STATE_RECEIVED:
        yield put(A.socketBookedShipmentStateReceived(data));
        break;
      default:
    }
  }
}

const runStompClientLoadBoardShipmentSubscription = (
  client: Object,
  { userGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getLoadBoardResults(userGuid);

  client.subscribe(topic, (message: Object) => {
    if (G.isNilOrEmpty(message.body)) return;

    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_RESULT_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

const runStompClientLoadBoardStatesSubscription = (
  client: Object,
  { accessToken, userGuid }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getLoadBoardLoginState(userGuid);

  client.subscribe(topic, (message: Object) => {
    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_STATE_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

const runStompClientLoadBoardConfigStateSubscription = (
  client: Object,
  { branchGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getLoadBoardConfigState(branchGuid);

  client.subscribe(topic, (message: Object) => {
    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_CONFIG_STATE_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

const runStompClientLoadBoardFilterStateSubscription = (
  client: Object,
  { userGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getSearchFilterState(userGuid);

  client.subscribe(topic, (message: Object) => {
    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_FILTER_STATE_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

const runStompClientLoadBoardPostedShipmentStateSubscription = (
  client: Object,
  { userGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getPostedShipmentState(userGuid);

  client.subscribe(topic, (message: Object) => {
    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_POSTED_SHIPMENT_STATE_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

const runStompClientLoadBoardBookedShipmentStateSubscription = (
  client: Object,
  { userGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = loadBoardTopics.getBookedShipmentState(userGuid);

  client.subscribe(topic, (message: Object) => {
    const data = JSON.parse(message.body);

    emit({
      data,
      type: LC.SOCKET_CHANNEL_LOAD_BOARD_BOOKED_SHIPMENT_STATE_RECEIVED,
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

function* reconnectLoadBoardSocketSaga(type: string, action: Function) {
  yield delay(LC.SocketReconnectDelay);

  if (areSocketReconnectsOver(type)) return;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());
    yield take(refreshTokenSuccess);
  }

  const userData = yield select(makeSelectCurrentUserData());

  if (G.isNotNil(userData)) {
    yield put(action(userData));
  }
}

function* checkLoadBoardSocketDisconnect(client: Object, disconnectAction: Function) {
  const onDisconnect = new Promise((res: Function) => {
    client.onWebSocketError = (payload: Object) => res(payload);
    client.onWebSocketClose = (payload: Object) => res(payload);
  });

  yield onDisconnect;

  yield put(disconnectAction());
}

function* waitForSocketLBConnected(client: Object, action: Object) {
  const message = yield getConnectedPromise(client);

  yield delay(10);

  if (R.equals(message, GC.LOAD_BOARD_WS_CONNECT_ERROR)) {
    yield put(action(false));
  } else {
    yield put(action(true));
  }
}

function* watchLoadBoardSocketConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketLBConnectRequest),
        reconnectAction: take(A.socketLBStateReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const accessToken = G.getAuthTokenFromSession();

      const data = {
        accessToken,
        reconnectDelay: 0,
        endpoint: endpointsMap.loadBoardSocketService,
        userGuid: R.path(['payload', 'user_guid'], action),
        branchGuid: yield select(makeSelectCurrentBranchGuid()),
        authorizedLoadBoards: yield select(makeSelectAuthorizedLoadBoards()),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketLBConnected, client, A.socketLBStateConnected);

      const { payload: connected } = yield take(A.socketLBStateConnected);

      if (connected) {
        resetSocketReconnectCounter(LC.LB_STATE_SOCKET_TYPE);

        const lbStatesEventChannel = yield call(runStompClientLoadBoardStatesSubscription, client, data);
        const filtersEventChannel = yield call(runStompClientLoadBoardFilterStateSubscription, client, data);
        const configsEventChannel = yield call(runStompClientLoadBoardConfigStateSubscription, client, data);
        const postedShipmentEventChannel =
          yield call(runStompClientLoadBoardPostedShipmentStateSubscription, client, data);
        const bookedShipmentEventChannel =
          yield call(runStompClientLoadBoardBookedShipmentStateSubscription, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketLBStateDisconnectRequest),
          task: all([
            call(checkLoadBoardSocketDisconnect, client, A.socketLBStateDisconnectRequest),
            call(watchEventChannelMessagesSaga, configsEventChannel),
            call(watchEventChannelMessagesSaga, lbStatesEventChannel),
            call(watchEventChannelMessagesSaga, filtersEventChannel),
            call(watchEventChannelMessagesSaga, postedShipmentEventChannel),
            call(watchEventChannelMessagesSaga, bookedShipmentEventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) {
          configsEventChannel.close();
          lbStatesEventChannel.close();
          filtersEventChannel.close();
          postedShipmentEventChannel.close();
        }
      }

      if (R.not(G.isCurrentBranchTypeCustomer())) {
        yield fork(reconnectLoadBoardSocketSaga, LC.LB_STATE_SOCKET_TYPE, A.socketLBStateReconnectRequest);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchLoadBoardLoadSocketConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketLBConnectRequest),
        reconnectAction: take(A.socketLBResultReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const accessToken = G.getAuthTokenFromSession();

      const data = {
        accessToken,
        reconnectDelay: 0,
        endpoint: endpointsMap.loadBoardLoadSocketService,
        userGuid: R.path(['payload', 'user_guid'], action),
        authorizedLoadBoards: yield select(makeSelectAuthorizedLoadBoards()),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketLBConnected, client, A.socketLBResultConnected);

      const { payload: connected } = yield take(A.socketLBResultConnected);

      if (connected) {
        resetSocketReconnectCounter(LC.LB_RESULT_SOCKET_TYPE);

        const resultsEventChannel = yield call(runStompClientLoadBoardShipmentSubscription, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketLBResultDisconnectRequest),
          task: all([
            call(checkLoadBoardSocketDisconnect, client, A.socketLBResultDisconnectRequest),
            call(watchEventChannelMessagesSaga, resultsEventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) {
          resultsEventChannel.close();
        }
      }

      if (R.not(G.isCurrentBranchTypeCustomer())) {
        yield fork(reconnectLoadBoardSocketSaga, LC.LB_RESULT_SOCKET_TYPE, A.socketLBResultReconnectRequest);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchLoadBoardsSocketSaga() {
  yield fork(watchLoadBoardSocketConnectRequestSaga);
  yield fork(watchLoadBoardLoadSocketConnectRequestSaga);

  yield take(initialDataLoadSuccess);

  if (R.or(
    G.isCurrentBranchTypeCustomer(),
    R.not(G.hasAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE])),
  )) return;

  const currentUser = yield select(makeSelectCurrentUserData());

  yield put(A.socketLBConnectRequest(currentUser));
}

export default watchLoadBoardsSocketSaga;
