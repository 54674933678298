import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as GC from '../../constants';
// feature sockets
import * as A from './actions';
import * as LC from './constants';
//////////////////////////////////////////////////

const ROUTE_PATH_TEL = 'route/tel';
const ROUTE_PATH_DISPATCH_DETAILS_LOAD = 'route/load';

const handleSocketSendMessageToStore = createLogic({
  type: A.socketSendMessageToStore,
  process({ action, getState }: Object, dispatch: void, done: void) {
    const { data, type } = action.payload;
    if (R.equals(type, LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED)) {
      dispatch(A.updateNotificationsStore(data));
    }
    done();
  },
});

// TODO: check this logic with new tel details
const handleSocketSendDisconnectTelDetailPage = createLogic({
  type: GC.LOCATION_CHANGE_ACTION,
  process({ action }: Object, dispatch: void, done: void) {
    const pathname = R.pathOr([], ['payload', 'pathname'], action);
    const condition = R.or(
      R.includes(ROUTE_PATH_TEL, pathname),
      R.includes(ROUTE_PATH_DISPATCH_DETAILS_LOAD, pathname),
    );
    if (R.not(condition)) {
      dispatch(A.socketTelDisconnectRequest());
    }
    done();
  },
});

export default [
  handleSocketSendMessageToStore,
  handleSocketSendDisconnectTelDetailPage,
];
