import * as GC from '../../constants';
//////////////////////////////////////////////////

export const LIST_OWN = 'roleList';
export const ADD_ROLE_FORM = 'ADD_ROLE_FORM';
export const EDIT_ROLE_FORM = 'EDIT_ROLE_FORM';
export const LIST_AVAILABLE = 'roleAvailableList';

export const groupNameLocale = {
  CLO: 'titles:clo',
  TEL: 'titles:tel',
  TASK: 'titles:task',
  USERS: 'titles:users',
  FLEET: 'titles:fleet',
  CARRIER: 'titles:carrier',
  TEMPLATES: 'titles:templates',
  ANALYTICS: 'titles:analytics',
  FINANCIALS: 'titles:financials',
  [GC.BRANCHES]: 'titles:branches',
  SERVICE_VENDOR: 'titles:service-vendor',
  ADMINISTRATION: 'titles:administration',
};
