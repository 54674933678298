import * as R from 'ramda';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-report
import { itemSettings } from './item';
import { contactSettings } from './contact';
import { locationSettings } from './location';
import { containerSettings } from './container';
import { cloTemplateSettings } from './clo-template';
import { compensationSettings } from './compensation';
import { routeTemplateSettings } from './route-template';
import { sharedAccessorialSettings } from './shared-accessorial';
import FuelIndexVariableList from '../components/fuel-index-variable-list';
//////////////////////////////////////////////////

const tableSettings = {
  rowHeight: 55,
  minHeight: 320,
  cellFontSize: 14,
  titleFontSize: 12,
  titleRowHeight: 55,
  tableRowHeight: 55,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  expandableItems: false,
  searchableTitles: true,
};

export const defaultFieldStyles = {
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  inputWrapMargin: '5px',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
};

export const getTableSettingsByListType = (props: Object) => {
  const {
    listType,
    itemList,
    reportType,
    reportList,
    filterParams,
    selectedReport,
  } = props;

  const hasPinned = G.hasPinnedReports(reportList);
  const hasFilters = G.hasSearchCriteria(selectedReport, filterParams);

  let maxHeight = 'calc(100vh - 125px)';

  if (R.and(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 190px)';
  } else if (R.or(hasPinned, hasFilters)) {
    maxHeight = 'calc(100vh - 160px)';
  }

  const tableSettingsToUse = R.assoc('maxHeight', maxHeight, tableSettings);

  if (R.includes(reportType, [GC.SHARED_ACCESSORIAL_REPORT])) {
    return {
      ...tableSettingsToUse,
      checkBoxCellWidth: 27,
      expandableItems: true,
      allowSelectItems: false,
      expandedDetailsComponent: FuelIndexVariableList,
    };
  }

  if (R.equals(listType, 'list')) return tableSettingsToUse;

  const allowSelectAll = R.compose(
    R.lt(0),
    R.length,
    R.filter(R.propEq(false, 'disabled')),
  )(itemList);

  return R.assoc('allowSelectAll', allowSelectAll, tableSettingsToUse);
};

const settingsMap = {
  [GC.ITEM_REPORT]: itemSettings,
  [GC.CONTACT_BOOK_REPORT]: contactSettings,
  [GC.CLO_TEMPLATE_REPORT]: cloTemplateSettings,
  [GC.LOCATION_TEMPLATE_REPORT]: locationSettings,
  [GC.ROUTE_TEMPLATE_REPORT]: routeTemplateSettings,
  [GC.CONTAINER_TEMPLATE_REPORT]: containerSettings,
  [GC.COMPENSATION_TEMPLATE_REPORT]: compensationSettings,
  [GC.SHARED_ACCESSORIAL_REPORT]: sharedAccessorialSettings,
};

export const getFilterParamsByReportType = (reportType: string) => R.compose(
  R.mapObjIndexed(({ name, options, collection, placeholder, filterType = 'string' }: Object, value: string) => {
    let optionsToUse = options;

    if (G.isFunction(options)) optionsToUse = options();

    return ({
      value,
      collection,
      options: optionsToUse,
      [GC.FIELD_TYPE]: filterType,
      placeholder: G.ifElse(
        R.and(R.equals(filterType, 'selectMultiple'), R.isNil(placeholder)),
        { text: 'Choose a status', key: 'titles:choose-a-status' },
        placeholder,
      ),
      [GC.FIELD_NAME]: G.ifElse(
        G.isArray(name),
        `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
        G.getWindowLocale(name, '...'),
      ),
    });
  }),
  R.propOr({}, reportType),
)(settingsMap);

export const getFilterParamsByReportTypeForEditReport = (reportType: string) =>
  R.values(getFilterParamsByReportType(reportType));

export const getColumnSettingsByReportType = (reportType: string) => R.compose(
  R.map(({ name, width = 220, options, fieldType, customComponent, searchable = true }: Object) => ({
    name,
    width,
    options,
    searchable,
    customComponent,
    [GC.FIELD_TYPE]: fieldType,
  })),
  R.propOr({}, reportType),
)(settingsMap);

export const getWritePermissionsByReportType = (reportType: string) => {
  const permissionsMap = {
    [GC.CONTACT_BOOK_REPORT]: null,
    [GC.SHARED_ACCESSORIAL_REPORT]: null,
    [GC.CLO_TEMPLATE_REPORT]: [PC.CLO_WRITE],
    [GC.ITEM_REPORT]: [PC.ITEM_TEMPLATE_WRITE],
    [GC.ROUTE_TEMPLATE_REPORT]: [PC.TEL_WRITE],
    [GC.LOCATION_TEMPLATE_REPORT]: [PC.LOCATION_TEMPLATE_WRITE],
    [GC.CONTAINER_TEMPLATE_REPORT]: [PC.CONTAINER_TEMPLATE_WRITE],
    [GC.COMPENSATION_TEMPLATE_REPORT]: [PC.COMPENSATION_TEMPLATE_WRITE],
  };

  return R.propOr(null, reportType, permissionsMap);
};

export const getReadWritePermissionsByReportType = (reportType: string) => {
  const permissionsMap = {
    [GC.ITEM_REPORT]: [],
  };

  return R.propOr([], reportType, permissionsMap);
};

const titlePanelSettingsForReport = {
  withCount: true,
  popperWithCount: true,
  hiddenRightFilterInfo: false,
};

const multiswitchOptions = [
  {
    width: 100,
    value: 'listUp',
    name: G.getWindowLocale('titles:own-and-up', 'Own and up'),
  },
  {
    width: 100,
    value: 'listDown',
    name: G.getWindowLocale('titles:own-and-down', 'Own and down'),
  },
];

const titlePanelSettingsForListUpDown = {
  ...titlePanelSettingsForReport,
  options: multiswitchOptions,
  useMultiswitchComponent: true,
};

const upDownReports = [
  GC.ITEM_REPORT,
  GC.CONTACT_BOOK_REPORT,
  GC.LOCATION_TEMPLATE_REPORT,
  GC.CONTAINER_TEMPLATE_REPORT,
  GC.COMPENSATION_TEMPLATE_REPORT,
];

export const getTitlePanelSettingsByReportType = (listType: string, reportType: string) => {
  if (R.includes(reportType, upDownReports)) {
    const selectedIndex = G.ifElse(R.equals(listType, 'listUp'), 0, 1);

    return R.assoc('selectedOptionIndex', selectedIndex, titlePanelSettingsForListUpDown);
  }

  if (R.includes(reportType, [GC.CLO_TEMPLATE_REPORT, GC.ROUTE_TEMPLATE_REPORT, GC.SHARED_ACCESSORIAL_REPORT])) {
    return R.assoc('noExportable', true, titlePanelSettingsForReport);
  }

  return titlePanelSettingsForReport;
};

export const itemNameMap = {
  [GC.ITEM_REPORT]: GC.FIELD_ITEM_ID,
  [GC.COMPENSATION_TEMPLATE_REPORT]: GC.FIELD_NAME,
  [GC.LOCATION_TEMPLATE_REPORT]: GC.FIELD_TEMPLATE_ID,
};

export const itemFormTitleMap = {
  [GC.ITEM_REPORT]: ['titles:item', 'Item'],
  [GC.CONTACT_BOOK_REPORT]: ['titles:contact', 'Contact'],
  [GC.LOCATION_TEMPLATE_REPORT]: ['titles:location', 'Location'],
  [GC.CONTAINER_TEMPLATE_REPORT]: ['titles:container-template', 'Container Template'],
  [GC.SHARED_ACCESSORIAL_REPORT]: ['titles:shared-accessorial', 'Shared Accessorial'],
  [GC.COMPENSATION_TEMPLATE_REPORT]: ['titles:compensation-template', 'Compensation Template'],
};

export const titlesMap = {
  [GC.ITEM_REPORT]: ['titles:item-list', 'Item List'],
  [GC.LOCATION_TEMPLATE_REPORT]: ['titles:locations', 'Locations'],
  [GC.CONTACT_BOOK_REPORT]: ['titles:contact-book', 'Contact Book'],
  [GC.CLO_TEMPLATE_REPORT]: ['titles:clo-templates', 'CLO Template(s)'],
  [GC.ROUTE_TEMPLATE_REPORT]: ['titles:route-templates', 'Route Templates'],
  [GC.SHARED_ACCESSORIAL_REPORT]: ['titles:shared-accessorials', 'Shared Accessorials'],
  [GC.CONTAINER_TEMPLATE_REPORT]: ['titles:container-templates', 'Container Template(s)'],
  [GC.COMPENSATION_TEMPLATE_REPORT]: ['titles:compensation-templates', 'Compensation Template(s)'],
};

export const getRequiredFieldsByReportType = (reportType: string) => {
  const fieldsMap = {
    [GC.CONTACT_BOOK_REPORT]: [GC.FIELD_BRANCH_GUID],
    [GC.CLO_TEMPLATE_REPORT]: [GC.FIELD_TEMPLATE_GUID],
    [GC.CONTAINER_TEMPLATE_REPORT]: [GC.FIELD_BRANCH_GUID],
    [GC.COMPENSATION_TEMPLATE_REPORT]: [GC.FIELD_NAME, GC.BRANCH_GUID],
    [GC.ITEM_REPORT]: [GC.FIELD_ACTIVE, GC.FIELD_BRANCH_GUID, GC.FIELD_ITEM_ID],
    [GC.ROUTE_TEMPLATE_REPORT]: [GC.FIELD_ACTIVE, GC.FIELD_NAME, GC.FIELD_BRANCH_GUID],
    [GC.LOCATION_TEMPLATE_REPORT]: [GC.FIELD_ACTIVE, GC.FIELD_BRANCH_GUID, GC.FIELD_TEMPLATE_ID],
    [GC.SHARED_ACCESSORIAL_REPORT]: [
      GC.BRANCH_GUID,
      GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES,
    ],
  };

  return R.propOr([], reportType, fieldsMap);
};

export const getReferenceScopeByReportType = (reportType: string) => {
  const map = {
    [GC.ITEM_REPORT]: GC.REF_SCOPE_NAME_ITEM,
    [GC.LOCATION_TEMPLATE_REPORT]: GC.REF_SCOPE_NAME_LOCATION,
  };

  return R.prop(reportType, map);
};
