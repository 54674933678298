import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { openModal, closeModal } from '../../components/modal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// feature toll charges
import { RowActions } from './components/row-actions';
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings, columnSettings } from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectTruckList,
  makeSelectUsedReport,
  makeSelectDriverList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          disablePrompt={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest({ openLoader: true })}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (e: Object, data: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <RowActions
            truckList={props.truckList}
            openModal={props.openModal}
            driverList={props.driverList}
            closeModal={props.closeModal}
            guid={G.getGuidFromObject(data)}
            closeFixedPopup={props.closeFixedPopup}
            tollChargeTruckGuid={R.prop(GC.FIELD_TRUCK_GUID, data)}
            tollChargeDriverGuid={R.prop(GC.FIELD_DRIVER_GUID, data)}
            updateTruckTollChargeRequest={props.updateTruckTollChargeRequest}
            updateDriverTollChargeRequest={props.updateDriverTollChargeRequest}
          />
        ),
      })
    ),
  }),
  branch(
    ({ selectedReport }: Object) => G.isNilOrEmpty(selectedReport),
    renderNothing,
  ),
  pure,
);

const renderRowActions = (data: Object, handleClickEditIcon: Function) => (
  <AuthWrapper has={[PC.TOLL_CHARGE_WRITE]}>
    <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
      {I.threeDots()}
    </Box>
  </AuthWrapper>
);

const TollChargesListComponent = (props: Object) => {
  const {
    pl,
    height,
    loading,
    itemList,
    minHeight,
    maxHeight,
    reportList,
    totalCount,
    pagination,
    filterParams,
    tableMaxHeight,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    openedFromFleetProfile,
    handleTableTitleFilter,
  } = props;

  const requiredFields = [GC.FIELD_FUEL_CARDS_PAYROLL_GUID, GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID];

  const fieldsToUse = G.addRequiredFields(R.pathOr({}, ['fields'], props.selectedReport), requiredFields);
  const reportToUse = R.assoc('fields', fieldsToUse, props.selectedReport);

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
  ? R.assoc('maxHeight', tableMaxHeight, tableSettings)
  : G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    tableSettings,
    selectedReport,
  });

  return (
    <PageWrapper pl={pl} pt={15} pb={15} pr={15} height={height} minHeight={minHeight} maxHeight={maxHeight}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='0'
          withCount={true}
          popperWithCount={true}
          totalCount={totalCount}
          filterProps={FILTER_PARAMS}
          filterParams={filterParams}
          type={GC.TOLL_CHARGE_REPORT}
          hiddenRightFilterInfo={false}
          selectedReport={selectedReport}
          getItemListRequest={getItemListRequest}
          title={R.pathOr(G.getWindowLocale('titles:toll-charges', 'Toll Charges'), ['title'], props)}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={reportToUse}
          totalCount={totalCount}
          pagination={pagination}
          useSearchableColumns={true}
          withResizableColumns={true}
          itemList={R.values(itemList)}
          useNewTitleFilterInputs={true}
          columnSettings={columnSettings}
          titleSortValues={titleSortValues}
          tableSettings={tableSettingsToUse}
          tableTitleFilters={tableTitleFilters}
          handleLoadMoreEntities={getItemListRequest}
          handleTableTitleFilter={handleTableTitleFilter}
          loading={R.and(loading, G.isNilOrEmpty(itemList))}
          renderRightStickedComponent={(data: Object) => renderRowActions(data, handleClickEditIcon)}
          filterProps={R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS))}
        />
      </ZOrderWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  truckList: makeSelectTruckList(state),
  driverList: makeSelectDriverList(state),
  pagination: makeSelectPagination(state),
  totalCount: makeSelectTotalCount(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  requestPending: makeSelectReportStatus(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
}));

export default connect(mapStateToProps, {
  openModal,
  setReports,
  closeModal,
  setUsedReport,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
})(enhance(TollChargesListComponent));
