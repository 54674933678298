import * as R from 'ramda';
import { Client } from '@stomp/stompjs';
// helpers/constants
import * as GC from '../../constants';
import { isNotNilAndNotEmpty } from '../../helpers';
import { isNotProduction } from '../../helpers/env';
// utilities
import { createWSApiUrl } from '../../utilities/http';
// feature sockets
import * as LC from './constants';
//////////////////////////////////////////////////

const clientTopics = {
  getRouteTel: (data: string) => `/tel/${data}`,
  getUserIFTA: (data: string) => `/user/${data}/ifta`,
  getUserError: (data: string) => `/user/${data}/error`,
  getUserImport: (data: string) => `/user/${data}/import`,
  getCarrierPortal: (data: string) => `/tel/forCarrier/${data}`,
  getUserNotification: (data: string) => `/user/${data}/notification`,
  getUserMassActionError: (data: string) => `/user/${data}/massAction`,
  getAvailableDrivers: (data: string) => `/driver/${data}/availableDrivers`,
  getUserDocumentGeneration: (data: string) => `/user/${data}/documentGeneration`,
};

const loadBoardTopics = {
  getLoadBoardResults: (user: string) => `/user/${user}/load-board-load-service/load`,
  getLoadBoardLoginState: (user: string) => `/user/${user}/load-board-service/state/login`,
  getSearchFilterState: (user: string) => `/user/${user}/load-board-service/state/search-filter-state`,
  getPostedShipmentState: (user: string) => `/user/${user}/load-board-service/state/posted-shipment-state`,
  getBookedShipmentState: (user: string) => `/user/${user}/load-board-service/state/committed-shipment-state`,
  getLoadBoardConfigState: (guid: string) => `/enterprise/${guid}/load-board-service/state/load-board-config`,
};

const getConnectedPromise = (client: Object) => new Promise((res: Function) => {
  // eslint-disable-next-line no-param-reassign
  client.onConnect = (message: string) => {
    if (isNotProduction) console.log(message);

    res(message);
  };

  // eslint-disable-next-line no-param-reassign
  client.onWebSocketError = () => {
    res(GC.LOAD_BOARD_WS_CONNECT_ERROR);
  };
});

const getReconnectDelay = (reconnectDelay: number) => {
  if (R.equals(reconnectDelay, 0)) return 0;

  return R.or(reconnectDelay, 5000);
};

const openStompClient = ({ endpoint, accessToken, reconnectDelay }: Object) => {
  const url = createWSApiUrl(endpoint);

  const client = new Client({
    brokerURL: url,
    connectHeaders: {
      'access-token': accessToken,
    },
    reconnectDelay: getReconnectDelay(reconnectDelay),
    debug: (message: string) => {
      if (isNotProduction) console.log(message);
    },
  });

  client.activate();

  return client;
};

export {
  clientTopics,
  loadBoardTopics,
  openStompClient,
  getConnectedPromise,
};

export const unsubscribeFromDrivers = (driverGuids: Array, accessToken: string, client: Object) => {
  if (isNotNilAndNotEmpty(driverGuids)) {
    driverGuids.forEach((driverGuid: string) => client.unsubscribe(driverGuid, { 'access-token': accessToken }));
  }
};

const socketReconnects = {};

export const areSocketReconnectsOver = (type: string) => {
  if (socketReconnects[type]) {
    socketReconnects[type] += 1;

    if (socketReconnects[type] >= LC.MaxSocketReconnectTries) {
      console.warn(`Can't reconnect ${type} websocket anymore`);

      return true;
    }
  } else {
    socketReconnects[type] = 1;
  }

  return false;
};

export const resetSocketReconnectCounter = (type: string) => {
  socketReconnects[type] = 0;
};
