import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, select } from 'redux-saga/effects';
// common
import { showRequestStatusModal } from '../../common/actions';
// components
import { setAudioPlaying } from '../../components/audio-player/actions';
// features
import { makeSelectCurrentUserData } from '../auth/selectors';
import { refreshTokenRequest, refreshTokenSuccess } from '../auth/actions';
import {
  getItemListRequest as getCarrierInvoices,
  resetListAndPagination as resetCarrierInvoiceListAndPagination,
} from '../invoice/carrier/actions';
import {
  getItemListRequest as getCustomerInvoices,
  resetListAndPagination as resetCustomerInvoiceListAndPagination,
} from '../invoice/customer/actions';
import {
  getItemListRequest as getServiceVendorInvoices,
  resetListAndPagination as resetServiceVendorInvoiceListAndPagination,
} from '../invoice/service-vendor/actions';
import {
  getItemListRequest as getVendorInvoices,
  resetListAndPagination as resetVendorInvoiceListAndPagination,
} from '../invoice/vendor/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import endpointsMap from '../../utilities/endpoints';
// feature sockets
import * as A from './actions';
import * as LC from './constants';
import {
  clientTopics,
  openStompClient,
  getConnectedPromise,
  areSocketReconnectsOver,
  resetSocketReconnectCounter,
} from './helpers';
import watchLoadBoardsSocketSaga from './sagas/load-boards';
//////////////////////////////////////////////////

function* watchSendStompMessageRequestSaga(client: Object) {
  while (true) { // eslint-disable-line
    const action = yield take(A.socketSendMessageRequest);

    const {
      payload: { destination, body = '', headers = {} },
    } = action;

    client.send(destination, body, headers);
  }
}

const isSocketFailed = (payloadType: string) => R.or(
  R.equals(payloadType, LC.SOCKET_CHANNEL_SOCKET_CLOSE),
  R.equals(payloadType, LC.SOCKET_CHANNEL_SOCKET_ERROR),
);

function* reconnectSocketSaga(type: string, action: Function) {
  yield delay(LC.SocketReconnectDelay);

  if (areSocketReconnectsOver(type)) return;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());
    yield take(refreshTokenSuccess);
  }

  const userData = yield select(makeSelectCurrentUserData());

  if (G.isNotNil(userData)) {
    yield put(action(userData));
  }
}

function* showNotificationReceivedToaster(data: Object) {
  const warningLevel = R.path(['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL], data);

  const warningLevelsData = {
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_INFO]: 'info',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL]: 'error',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING]: 'warning',
  };

  const text = G.getWindowLocale(
    'messages:new-notification-received',
    'You have a new notification received. Please, check it.',
  );

  yield call(
    G.showToastrMessage,
    R.propOr('info', warningLevel, warningLevelsData),
    text,
    { disableCloseButtonFocus: true },
  );

  const isForTel = R.pathEq(
    GC.NOTIFICATION_OBJECT_TYPE_TEL,
    ['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE],
    data,
  );

  const isImportant = R.or(
    R.equals(warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING),
    R.equals(warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL),
  );

  if (R.path(['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ALARM_ENABLED], data)) {
    yield put(setAudioPlaying(true));
  }

  if (R.and(isForTel, isImportant)) yield put(A.socketTelWarningReceived(data));
}

const downloadFile = ({ url, fileName }: Object) => G.downloadFileFromUrl(url, fileName);

function* watchUserDocumentGeneratedReceivedSaga() {
  while (true) { // eslint-disable-line
    yield delay(2000);

    if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
      window.amousSocketUserDocumentGeneratedReceived = [];
    }

    const spliced = window.amousSocketUserDocumentGeneratedReceived.splice(0, 1)[0];

    if (G.isNotNilAndNotEmpty(spliced)) downloadFile(spliced);
  }
}

const successToDisconnectActionMap = {
  [LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED]: A.socketImportDisconnectRequest,
  [LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED]: A.socketUserErrorDisconnectRequest,
  [LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_RECEIVED]: A.socketAvailableDriversDisconnectRequest,
  [LC.SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED]: A.socketAllDriversDisconnectRequest,
  [LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED]: A.socketUserNotificationDisconnectRequest,
  [LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED]: A.socketUserMassActionErrorDisconnectRequest,
  [LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED]: A.socketUserErrorFromCarrierDisconnectRequest,
  [LC.SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketUserDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketRouteDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketFleetDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketBranchDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketCarrierDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketStatisticDocumentGeneratedDisconnectRequest,
  [LC.SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED]: A.socketTemplatesDocumentGeneratedDisconnectRequest,
};

const documentGenerationTypes = [
  LC.SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
];

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);
    const { type, payload, successType } = event;

    const socketDataType = R.path(['data', LC.SOCKET_DATA_TYPE], payload);

    if (isSocketFailed(type)) {
      // TODO: check disconnect for all cases
      const disconnectAction = R.path([successType], successToDisconnectActionMap);

      if (G.isFunction(disconnectAction)) {
        console.warn(successType);

        yield put(disconnectAction());
      }
    } else if (R.equals(type, LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED)) {
      yield call(showNotificationReceivedToaster, payload.data);

      yield put(A.socketSendMessageToStore(payload));
    } else if (R.includes(
      type,
      [
        LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED,
        LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED,
      ],
    )) {
      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_FRONT_ERROR)) {
        console.log('//////////////////// SOCKET_FRONT_ERROR', event, '////////////////////');

        return;
      }

      const errorMessage = R.join(', ', R.pathOr([], ['data', 'errorMessages'], payload));

      yield call(G.showToastrMessage, 'error', errorMessage);
    } else if (R.includes(type, documentGenerationTypes)) {
      const data = R.path(['data', 'data'], payload);

      // TODO: check another approach to handle show created document
      yield put(A.socketUserDocumentGeneratedReceivedSuccess(data));

      if (G.isString(data)) {
        yield call(G.showToastrMessage, 'info', data);
      } else {
        const url = R.path([GC.FIELD_DOCUMENT_URL], data);
        const fileName = R.path([GC.FIELD_DOCUMENT_DOCUMENT_NAME], data);

        if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
          window.amousSocketUserDocumentGeneratedReceived = [];
        }

        window.amousSocketUserDocumentGeneratedReceived.push({ url, fileName });
      }
    } else if (R.equals(type, LC.SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED)) {
      const message = G.getWindowLocale('titles:ifta-report-competed', 'IFTA Report Completed');

      yield call(G.showToastrMessage, 'success', message);
    } else if (R.equals(type, LC.SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED)) {
      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_CHAT_MESSAGE)) {
        yield put(A.socketCarrierPortalReceived(payload.data));
      }
    } else if (R.equals(type, LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED)) {
      if (R.not(R.pathEq(GC.STATUS_IN_PROGRESS, ['data', GC.FIELD_STATUS], payload))) {
        const message = R.compose(
          R.join(', '),
          R.values,
          R.mapObjIndexed((item: string, key: string) => `${G.toTitleCase(key)}: ${item}`),
          R.filter(G.isNotZero),
          R.pick(['created', 'updated', 'failed', 'errors']),
          R.pathOr({}, ['data']),
        )(payload);

        yield put(A.saveImportListSuccess(payload.data));

        yield call(G.showToastrMessage, 'info', message);
      }
    } else if (R.equals(type, LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED)) {
      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_CHAT_MESSAGE)) {
        yield put(A.socketTelChatMessageReceived(payload.data));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_LOAD_STATUS)) {
        yield put(A.socketTelLoadStatusReceived(R.path(['data', 'data'], payload)));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_RATE_STATUS)) {
        yield put(A.socketTelRateStatusReceived(R.path(['data', 'data'], payload)));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_EVENT_STATUS)) {
        yield put(A.socketTelEventStatusReceived(payload.data));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_STATUS_MESSAGE)) {
        yield put(A.socketTelStatusMessageReceived(payload.data));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_DOCUMENT)) {
        yield put(A.socketTelDocumentReceived(R.path(['data'], payload)));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_COST_ALLOCATION)) {
        yield put(A.socketTelCostAllocationsReceived(R.path(['data'], payload)));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_RATE)) {
        yield put(A.socketTelRateReceived(R.path(['data'], payload)));
      }

      if (R.equals(socketDataType, LC.SOCKET_DATA_TYPE_CLO_STATUS_TO_TEL)) {
        yield put(A.socketCloStatusToTelReceived(R.path(['data'], payload)));
      }
    } else if (R.equals(type, LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED)) {
      if (R.includes(
        socketDataType,
        [
          LC.SOCKET_DATA_TYPE_EDI_EXPORT_RESULT,
          LC.SOCKET_DATA_TYPE_EXPORT_TO_ACCOUNTING,
        ],
      )) {
        const { status, totalCount, successCount } = R.pathOr({}, ['data', 'data'], payload);

        const errors = R.compose(
          R.values,
          R.mapObjIndexed((messageArray: array, key: string) => ({ key, messageArray })),
          R.pathOr({}, ['data', 'data', 'errors']),
        )(payload);

        const pageType = R.path(['data', 'data', 'type'], payload);
        const currentPage = R.path(['location', 'pathname'], window);

        if (R.and(R.equals(pageType, 'CUSTOMER'), R.equals(currentPage, GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST))) {
          yield put(resetCustomerInvoiceListAndPagination());
          yield put(getCustomerInvoices(true));
        } else if (R.and(R.equals(pageType, 'CARRIER'), R.equals(currentPage, GC.ROUTE_PATH_CARRIER_INVOICES_LIST))) {
          yield put(resetCarrierInvoiceListAndPagination());
          yield put(getCarrierInvoices(true));
        } else if (R.and(
          R.equals(pageType, 'SERVICE_VENDOR'),
          R.equals(currentPage, GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST),
        )) {
          yield put(resetServiceVendorInvoiceListAndPagination());
          yield put(getServiceVendorInvoices(true));
        } else if (R.and(
          R.equals(pageType, 'FLEET_VENDOR'),
          R.equals(currentPage, GC.ROUTE_PATH_VENDOR_INVOICES_LIST),
        )) {
          yield put(resetVendorInvoiceListAndPagination());
          yield put(getVendorInvoices(true));
        }

        const exportResultTypeLocaleArr = G.ifElse(
          R.equals(socketDataType, LC.SOCKET_DATA_TYPE_EDI_EXPORT_RESULT),
          ['titles:edi', 'EDI'],
          ['titles:accounting', 'Accounting'],
        );

        const title = `${G.getWindowLocale(...exportResultTypeLocaleArr)} ${
          G.getWindowLocale('titles:export-result', 'Export Result')}`;

        yield put(showRequestStatusModal({
          title,
          status,
          errors,
          totalCount,
          successCount,
        }));
      } else {
        const errorMessage = R.compose(
          R.join('; '),
          R.values,
          R.mapObjIndexed((msgArr: array, key: string) => `${key}: ${R.join(', ', msgArr)}`),
          R.pathOr({}, ['data', 'data', 'errors']),
        )(payload);

        yield call(G.showToastrMessage, 'error', errorMessage);
      }
    }
  }
}

// USER NOTIFICATION
const runStompClientSubUserNotification = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken } = data;
  const topic = clientTopics.getUserNotification(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_USER_NOTIFICATION_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketUserNotificationConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketUserNotificationConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketUserNotificationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserNotificationConnectRequest),
        reconnectAction: take(A.socketUserNotificationReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.notificationWS,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketUserNotificationConnected, client, LC.USER_NOTIFICATION_SOCKET_TYPE);
      yield take(A.socketUserNotificationConnected);

      const eventChannel = yield call(runStompClientSubUserNotification, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketUserNotificationDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(reconnectSocketSaga, LC.USER_NOTIFICATION_SOCKET_TYPE, A.socketUserNotificationReconnectRequest);
    }
  } catch (error) {
    console.log('////////////////////////////////////////////', 'catch watchSocketUserNotificationConnectRequestSaga');
  }
}
// USER NOTIFICATION

// USER NOTIFICATION ERROR
const runStompClientSubUserError = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken } = data;
  const topic = clientTopics.getUserError(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_USER_ERROR_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketUserErrorConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketUserErrorConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketUserErrorConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserErrorConnectRequest),
        reconnectAction: take(A.socketUserErrorReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketUserErrorConnected, client, LC.USER_ERROR_SOCKET_TYPE);
      yield take(A.socketUserErrorConnected);

      const eventChannel = yield call(runStompClientSubUserError, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketUserErrorDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(reconnectSocketSaga, LC.USER_ERROR_SOCKET_TYPE, A.socketUserErrorReconnectRequest);
    }
  } catch (error) {
    console.log('////////////////////////////////////////////', 'catch watchSocketUserErrorConnectRequestSaga');
  }
}
// USER NOTIFICATION ERROR

// USER NOTIFICATION ERROR FROM CARRIER
const runStompClientSubUserErrorFromCarrier = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken } = data;

  const topic = clientTopics.getUserError(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_USER_ERROR_FROM_CARRIER_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketUserErrorFromCarrierConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketUserErrorFromCarrierConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketUserErrorFromCarrierConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserErrorFromCarrierConnectRequest),
        reconnectAction: take(A.socketUserErrorFromCarrierReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.carrierSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketUserErrorFromCarrierConnected, client, LC.USER_ERROR_FROM_CARRIER_SOCKET_TYPE);
      yield take(A.socketUserErrorFromCarrierConnected);

      const eventChannel = yield call(runStompClientSubUserErrorFromCarrier, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketUserErrorFromCarrierDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.USER_ERROR_FROM_CARRIER_SOCKET_TYPE,
        A.socketUserErrorFromCarrierReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '////////////////////////////////////////////', 'catch watchSocketUserErrorFromCarrierConnectRequestSaga',
    );
  }
}
// USER NOTIFICATION ERROR FROM CARRIER

// USER MASS ACTION ERROR
const runStompClientSubUserMassActionError = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken } = data;
  const topic = clientTopics.getUserMassActionError(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_USER_MASS_ACTION_ERROR_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketUserMassActionErrorConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketUserMassActionErrorConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketUserMassActionErrorConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserMassActionErrorConnectRequest),
        reconnectAction: take(A.socketUserMassActionErrorReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketUserMassActionErrorConnected, client, LC.USER_MASS_ACTION_ERROR_SOCKET_TYPE);
      yield take(A.socketUserErrorConnected);

      const eventChannel = yield call(runStompClientSubUserMassActionError, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketUserMassActionErrorDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.USER_MASS_ACTION_ERROR_SOCKET_TYPE,
        A.socketUserMassActionErrorReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '////////////////////////////////////////////',
      'catch watchSocketUserMassActionErrorConnectRequestSaga',
    );
  }
}
// USER MASS ACTION ERROR

// USER DOCUMENT GENERATION SUBSCRIBE
const runStompClientSubUserDocumentGeneration = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken, socketChannelType } = data;

  const topic = clientTopics.getUserDocumentGeneration(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: socketChannelType,
      payload: {
        type: socketChannelType,
        data: JSON.parse(message.body),
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      successType: socketChannelType,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      successType: socketChannelType,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});
// USER DOCUMENT GENERATION SUBSCRIBE

// ROUTE DOCUMENT GENERATION
function* waitForSocketRouteDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketRouteDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketRouteDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketRouteDocumentGeneratedRequest),
        reconnectAction: take(A.socketRouteDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketRouteDocumentGeneratedConnected, client, LC.ROUTE_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketRouteDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketRouteDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.ROUTE_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketRouteDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketRouteDocumentGenerationConnectRequestSaga');
  }
}
// ROUTE DOCUMENT GENERATION

// USER DOCUMENT GENERATION
function* waitForSocketUserDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketUserDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketUserDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserDocumentGeneratedRequest),
        reconnectAction: take(A.socketUserDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.userSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketUserDocumentGeneratedConnected, client, LC.USER_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketUserDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketUserDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.USER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketUserDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketUserDocumentGenerationConnectRequestSaga');
  }
}
// USER DOCUMENT GENERATION

// CARRIER DOCUMENT GENERATION
function* waitForSocketCarrierDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketCarrierDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketCarrierDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketCarrierDocumentGeneratedRequest),
        reconnectAction: take(A.socketCarrierDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.carrierSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketCarrierDocumentGeneratedConnected, client, LC.CARRIER_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketCarrierDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketCarrierDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.CARRIER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketCarrierDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketCarrierDocumentGenerationConnectRequestSaga',
    );
  }
}
// CARRIER DOCUMENT GENERATION

// BRANCH DOCUMENT GENERATION
function* waitForSocketBranchDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketBranchDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketBranchDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketBranchDocumentGeneratedRequest),
        reconnectAction: take(A.socketBranchDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.branchSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketBranchDocumentGeneratedConnected, client, LC.BRANCH_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketBranchDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketBranchDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.BRANCH_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketBranchDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketBranchDocumentGenerationConnectRequestSaga',
    );
  }
}
// BRANCH DOCUMENT GENERATION

// FLEET DOCUMENT GENERATION
function* waitForSocketFleetDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketFleetDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketFleetDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketFleetDocumentGeneratedRequest),
        reconnectAction: take(A.socketFleetDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.fleetSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketFleetDocumentGeneratedConnected, client, LC.FLEET_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketFleetDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketFleetDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.FLEET_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketFleetDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketFleetDocumentGenerationConnectRequestSaga');
  }
}
// FLEET DOCUMENT GENERATION

// STATISTIC DOCUMENT GENERATION
function* waitForSocketStatisticDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketStatisticDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketStatisticDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketStatisticDocumentGeneratedRequest),
        reconnectAction: take(A.socketStatisticDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        accessToken: G.getAuthTokenFromSession(),
        endpoint: endpointsMap.statisticWebsocket,
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketStatisticDocumentGeneratedConnected, client, LC.STATISTIC_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketStatisticDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketStatisticDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.STATISTIC_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketStatisticDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketStatisticDocumentGenerationConnectRequestSaga',
    );
  }
}
// STATISTIC DOCUMENT GENERATION

// TEMPLATES DOCUMENT GENERATION
function* waitForSocketTemplatesDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketTemplatesDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketTemplatesDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketTemplatesDocumentGeneratedRequest),
        reconnectAction: take(A.socketTemplatesDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        accessToken: G.getAuthTokenFromSession(),
        endpoint: endpointsMap.templatesWebsocket,
        userGuid: R.path(['payload', 'user_guid'], action),
        socketChannelType: LC.SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketTemplatesDocumentGeneratedConnected, client, LC.TEMPLATES_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketTemplatesDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketTemplatesDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.TEMPLATES_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketTemplatesDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketTemplatesDocumentGenerationConnectRequestSaga',
    );
  }
}
// TEMPLATES DOCUMENT GENERATION

// IFTA DOCUMENT GENERATION
const runStompClientSubIftaDocumentGeneration = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken } = data;
  const topic = clientTopics.getUserIFTA(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_IFTA_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketIftaDocumentGeneratedConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketIftaDocumentGeneratedConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketIftaDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketIftaDocumentGeneratedRequest),
        reconnectAction: take(A.socketIftaDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.fleetSocket,
        accessToken: G.getAuthTokenFromSession(),
        userGuid: R.path(['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketIftaDocumentGeneratedConnected, client, LC.IFTA_DOCUMENT_GENERATED_SOCKET_TYPE);
      yield take(A.socketIftaDocumentGeneratedConnected);

      const eventChannel = yield call(runStompClientSubIftaDocumentGeneration, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketIftaDocumentGeneratedDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(
        reconnectSocketSaga,
        LC.IFTA_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketIftaDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketIftaDocumentGenerationConnectRequestSaga');
  }
}
// IFTA DOCUMENT GENERATION

// IMPORT
const runStompClientSubImport = (
  client: Object,
  { userGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = clientTopics.getUserImport(userGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_IMPORT_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* waitForSocketImportConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketImportConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketImportConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketImportConnectRequest),
        reconnectAction: take(A.socketImportReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        accessToken: G.getAuthTokenFromSession(),
        endpoint: endpointsMap.statisticWebsocket,
        userGuid: R.pathOr(G.getAmousCurrentUserGuidFromWindow(), ['payload', 'user_guid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketImportConnected, client, LC.IMPORT_SOCKET_TYPE);
      yield take(A.socketImportConnected);

      const eventChannel = yield call(runStompClientSubImport, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketImportDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(reconnectSocketSaga, LC.IMPORT_SOCKET_TYPE, A.socketImportReconnectRequest);
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketTelConnectRequestSaga');
  }
}
// IMPORT

// TEL
const runStompClientSubTel = (
  client: Object,
  { telGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = clientTopics.getRouteTel(telGuid);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* reconnectSocketTelSaga(action: Object) {
  yield delay(LC.SocketReconnectDelay);

  if (areSocketReconnectsOver(LC.TEL_SOCKET_TYPE)) return;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());
    yield take(refreshTokenSuccess);
  }

  const options = {
    endpoint: endpointsMap.routeSocket,
    telGuid: R.path(['payload', 'telGuid'], action),
  };

  if (G.isNotNil(action)) {
    yield put(A.socketTelReconnectRequest(options));
  }
}

function* waitForSocketTelConnected(client: Object, type: string) {
  yield getConnectedPromise(client);
  yield put(A.socketTelConnected());

  resetSocketReconnectCounter(type);
}

function* watchSocketTelConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketTelConnectRequest),
        reconnectAction: take(A.socketTelReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: G.getAuthTokenFromSession(),
        telGuid: R.path(['payload', 'telGuid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketTelConnected, client, LC.TEL_SOCKET_TYPE);
      yield take(A.socketTelConnected);

      const eventChannel = yield call(runStompClientSubTel, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketTelDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(reconnectSocketTelSaga, action);
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketTelConnectRequestSaga');
  }
}
// TEL

// TEL FOR CARRIER
const runStompCarrierPortalSubscriptions = (
  client: Object,
  { accessToken }: Object,
) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const topic = clientTopics.getCarrierPortal(accessToken);

  client.subscribe(topic, (message: Object) => {
    emit({
      type: LC.SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED,
      payload: {
        data: JSON.parse(message.body),
        type: LC.SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  client.onWebSocketError = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_ERROR,
      successType: LC.SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED,
    });
  });

  client.onWebSocketClose = ((payload: Object) => {
    emit({
      payload,
      type: LC.SOCKET_CHANNEL_SOCKET_CLOSE,
      successType: LC.SOCKET_CHANNEL_TEL_FOR_CARRIER_MESSAGE_RECEIVED,
    });
  });

  return () => client.onDisconnect(() => {}, { 'access-token': accessToken });
});

function* reconnectCarrierPortalSocketSaga() {
  yield delay(LC.SocketReconnectDelay);

  if (areSocketReconnectsOver(LC.CARRIER_PORTAL_SOCKET_TYPE)) return;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());
    yield take(refreshTokenSuccess);
  }

  const token = G.parseQueryString();
  const carrierToken = R.assoc('access_token', token, {});

  if (G.isNotNil(token)) yield put(A.socketCarrierPortalReconnectRequest(carrierToken));
}

function* waitForCarrierPortalConnected(client: Object, type: string) {
  let finished = false;

  while (R.not(finished)) {
    yield delay(1000);

    if (client.connected) {
      finished = true;
      yield put(A.socketCarrierPortalConnected());

      resetSocketReconnectCounter(type);
    }
  }
}

function* watchSocketCarrierPortalConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketCarrierPortalConnectRequest),
        reconnectAction: take(A.socketCarrierPortalReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        accessToken: R.path(['payload', 'access_token', 'token'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForCarrierPortalConnected, client, LC.CARRIER_PORTAL_SOCKET_TYPE);
      yield take(A.socketCarrierPortalConnected);

      const eventChannel = yield call(runStompCarrierPortalSubscriptions, client, data);

      const { cancel } = yield race({
        cancel: take(A.socketCarrierPortalDisconnectRequest),
        task: all([
          call(watchEventChannelMessagesSaga, eventChannel),
          call(watchSendStompMessageRequestSaga, client),
        ]),
      });

      if (cancel) eventChannel.close();

      yield fork(reconnectCarrierPortalSocketSaga);
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketCarrierPortalConnectRequestSaga');
  }
}
// TEL FOR CARRIER

function* socketWatcherSaga() {
  yield fork(watchLoadBoardsSocketSaga);
  yield fork(watchSocketTelConnectRequestSaga);
  yield fork(watchSocketImportConnectRequestSaga);
  yield fork(watchSocketUserErrorConnectRequestSaga);
  yield fork(watchUserDocumentGeneratedReceivedSaga);
  yield fork(watchSocketCarrierPortalConnectRequestSaga);
  yield fork(watchSocketUserNotificationConnectRequestSaga);
  yield fork(watchSocketUserMassActionErrorConnectRequestSaga);
  yield fork(watchSocketUserErrorFromCarrierConnectRequestSaga);
  yield fork(watchSocketIftaDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketUserDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketFleetDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketRouteDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketBranchDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketCarrierDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketStatisticDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketTemplatesDocumentGenerationConnectRequestSaga);
}

export default socketWatcherSaga;
