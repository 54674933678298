import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const RC = {
  FAX: `${GC.FIELD_CONTACTS}.${GC.FIELD_FAX}`,
  EMAIL: `${GC.FIELD_CONTACTS}.${GC.FIELD_EMAIL}`,
  PHONE: `${GC.FIELD_CONTACTS}.${GC.FIELD_PHONE}`,
  CONTACT_NAME: `${GC.FIELD_CONTACTS}.${GC.FIELD_CONTACT_NAME}`,
  PHONE_EXTENSION: `${GC.FIELD_CONTACTS}.${GC.FIELD_PHONE_EXTENSION}`,
  LOCATION_TYPE_DISPLAYED_VALUE: `${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
};

export const locationSettings = {
  [GC.FIELD_ACTIVE]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_CROSSDOCK]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:crossdock',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    name: 'titles:branch',
  },
  [RC.LOCATION_TYPE_DISPLAYED_VALUE]: {
    name: 'titles:type',
  },
  [GC.FIELD_LOCATION_NAME]: {
    name: 'titles:name',
  },
  [GC.FIELD_TEMPLATE_ID]: {
    name: 'titles:template-id',
  },
  [GC.FIELD_ADDRESS_1]: {
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    name: 'titles:address2',
  },
  [GC.FIELD_ADDRESS_3]: {
    name: 'titles:address3',
  },
  [GC.FIELD_ADDRESS_4]: {
    name: 'titles:address4',
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
  },
  [GC.FIELD_STATE]: {
    name: 'titles:state',
  },
  [GC.FIELD_ZIP]: {
    name: 'titles:zip',
  },
  [GC.FIELD_COUNTRY]: {
    name: 'titles:country',
  },
  [GC.FIELD_COMMENTS]: {
    name: 'titles:comments',
  },
  [GC.FIELD_LATITUDE]: {
    filterType: 'number',
    name: 'titles:latitude',
  },
  [GC.FIELD_LONGITUDE]: {
    filterType: 'number',
    name: 'titles:longitude',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:last-modified-date',
  },
  [RC.CONTACT_NAME]: {
    name: 'titles:contact-name',
  },
  [RC.PHONE]: {
    name: 'titles:phone',
  },
  [RC.PHONE_EXTENSION]: {
    name: 'titles:phone-extension',
  },
  [RC.EMAIL]: {
    name: 'titles:email',
  },
  [RC.FAX]: {
    name: 'titles:fax',
  },
};
