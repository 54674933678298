import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { socketUserDocumentGeneratedRequest } from '../sockets/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import {
  transformSearchCriteriaBeforeReportPost,
  transformSearchCriteriaBeforeFilterPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature users
import * as A from './actions';
import { FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

export function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)))
    ) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const fieldNames = R.map(({ name }: Object) => name, R.propOr('fields', [], reportParams));

    const fields = G.ifElse(
      R.includes(GC.FIELD_USER_TYPE, fieldNames),
      reportParams.fields,
      R.concat(
        G.getOrElse(reportParams, 'fields', []),
        R.of(Array, { name: GC.FIELD_USER_TYPE, freezed: false, sequence: 101, reference: false }),
      ),
    );

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      fields,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    if (G.isNotEmpty(reqBody.fields)) {
      const requiredFields = [GC.FIELD_BRANCH_GUID];

      reqBody.fields = G.addRequiredFields(reqBody.fields, requiredFields);
    }

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.listUsers, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, error, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { fields, fileType, orderFields, searchCriteria } = payload;

    const reqBody = {
      fields,
      orderFields,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.userExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handleCreateNewUserRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload.values),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.createUser, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createNewUserSuccess(data));

      yield call(G.goToRoute, routesMap.getUserSettingsRoute(data.guid));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNewUserRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewUserRequest exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const url = yield call(endpointsMap.getRemoveUserEndpoint, payload);
    const res = yield call(sendRequest, 'delete', url);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* handleAssignRolesRequest(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.assignRoles, { data: action.payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.assignRolesSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleAssignRolesRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAssignRolesRequest exception');
  }
}

function* handleRevokeRolesRequest(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.revokeRoles, { data: action.payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.revokeRolesSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleRevokeRolesRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRevokeRolesRequest exception');
  }
}

function* handleCreateUsersReferenceSaga(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: R.assoc('primaryObjectGuids', action.payload.selectedList, action.payload.values),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.userReference, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest());
      yield put(A.createUsersReferenceSuccess(options.data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateUsersReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateUsersReferenceSaga exception');
  }
}

function* getPasswordConfigsForUsersSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        group: GC.PASSWORD_CONFIG_GROUP,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPasswordConfigsForUsersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getPasswordConfigsForUsersSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getPasswordConfigsForUsersSaga exception');
  }
}

function* handleGetAvailableByTypeRoleListRequest({ payload }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const params = R.assoc(GC.BRANCH_GUID, branchGuid, payload);

    const res = yield call(sendRequest, 'get', endpointsMap.availableRolesByType, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableByTypeRoleListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'handleGetAvailableByTypeRoleListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAvailableByTypeRoleListRequest exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FILTER_PARAMS));
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.USER_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

export function* handleVisitUsersListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_USERS_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(socketUserDocumentGeneratedRequest(G.getAmousCurrentUserFromWindow()));
    yield put(A.setIgnorePromptStatus(false));

    const roleType = G.getAmousUserTypeFromWindow();

    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_USER));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield call(handleSetFilterParams);

    yield put(A.getAvailableByTypeRoleListRequest({ roleType }));

    yield put(closeLoader());

    break;
  }
}

export function* handleVisitUserNewSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_USER_CREATE_PAGE);
    yield call(getPasswordConfigsForUsersSaga);

    const roleType = G.ifElse(
      R.pathEq(GC.BRANCH_TYPE_ENUM_CUSTOMER, [GC.BRANCH_TYPE], G.getAmousCurrentBranchFromWindow()),
      GC.USER_ROLE_TYPE_CUSTOMER,
      GC.USER_ROLE_TYPE_GENERAL,
    );

    yield put(A.getAvailableByTypeRoleListRequest({ roleType }));

    break;
  }
}

export function* userWatcherSaga() {
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.assignRolesRequest, handleAssignRolesRequest);
  yield takeLatest(A.revokeRolesRequest, handleRevokeRolesRequest);
  yield takeLatest(GC.VISIT_USER_CREATE_PAGE, handleVisitUserNewSaga);
  yield takeLatest(A.createNewUserRequest, handleCreateNewUserRequest);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(GC.VISIT_USERS_LIST_PAGE, handleVisitUsersListPageSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.createUsersReferenceRequest, handleCreateUsersReferenceSaga);
  yield takeLatest(A.getAvailableByTypeRoleListRequest, handleGetAvailableByTypeRoleListRequest);
}

export default userWatcherSaga;
