import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const checkAllRoles = createAction('checkAllRoles');
export const setActiveList = createAction('setActiveList');
export const addNewRoleFail = createAction('addNewRoleFail');
export const removeRoleFail = createAction('removeRoleFail');
export const updateRoleFail = createAction('updateRoleFail');
export const getRoleListFail = createAction('getRoleListFail');
export const addNewRoleRequest = createAction('addNewRoleRequest');
export const addNewRoleSuccess = createAction('addNewRoleSuccess');
export const updateRoleRequest = createAction('updateRoleRequest');
export const updateRoleSuccess = createAction('updateRoleSuccess');
export const removeRoleRequest = createAction('removeRoleRequest');
export const removeRoleSuccess = createAction('removeRoleSuccess');
export const getRoleListRequest = createAction('getRoleListRequest');
export const getRoleListSuccess = createAction('getRoleListSuccess');
export const resetUserPermissions = createAction('resetUserPermissions');
export const togglePermissionGroups = createAction('togglePermissionGroups');
export const grandRoleToChildRequest = createAction('grandRoleToChildRequest');
export const handleToggleGroupRequest = createAction('handleToggleGroupRequest');
export const getRoleAvailableListFail = createAction('getRoleAvailableListFail');
export const availableRoleListRequest = createAction('availableRoleListRequest');
export const availableRoleListSuccess = createAction('availableRoleListSuccess');
export const addGroupPermissionsRequest = createAction('addGroupPermissionsRequest');
export const addPermissionsToRoleRequest = createAction('addPermissionsToRoleRequest');
export const ungrandRoleFromChildRequest = createAction('ungrandRoleFromChildRequest');
export const getRoleSelectedDocumentTypes = createAction('getRoleSelectedDocumentTypes');
export const getPermissionsGroupsListFail = createAction('getPermissionsGroupsListFail');
export const changePermissionCheckboxState = createAction('changePermissionCheckboxState');
export const getPermissionsGroupsListRequest = createAction('getPermissionsGroupsListRequest');
export const getPermissionsGroupsListSuccess = createAction('getPermissionsGroupsListSuccess');
export const getRolePermissionsGroupsListFail = createAction('getRolePermissionsGroupsListFail');
export const clearRolesStateAfterSwitchBranch = createAction('clearRolesStateAfterSwitchBranch');
export const getUserPermissionsByRoleTypeSuccess = createAction('getUserPermissionsByRoleTypeSuccess');
export const getUserPermissionsByRoleTypeRequest = createAction('getUserPermissionsByRoleTypeRequest');
export const getRolePermissionsGroupsListRequest = createAction('getRolePermissionsGroupsListRequest');
export const getRolePermissionsGroupsListSuccess = createAction('getRolePermissionsGroupsListSuccess');
export const handleRemoveSinglePermissionRequest = createAction('handleRemoveSinglePermissionRequest');
